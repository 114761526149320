import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import grittyApi from 'services/gritty';
import { AppDispatch } from 'store';

type ApiError = Record<string, unknown>;

export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  pictureUrl: string;
  isVerified: boolean;
  deletedAt: string;
  createdAt: string;
  lastLoginAt: string;
  completedOnboarding: boolean;
  isLoading: boolean;
  error: ApiError | null;
};

type UserSliceState = {
  data: UserType[];
  filters: {
    searchTerm: string | null;
  };
  isLoading: boolean;
  error: ApiError | null;
};

const initialState: UserSliceState = {
  data: [],
  filters: {
    searchTerm: null,
  },
  isLoading: false,
  error: null,
};

const startLoadingReducer: CaseReducer<UserSliceState> = (state) => ({
  ...state,
  isLoading: true,
});

const hasErrorReducer: CaseReducer<UserSliceState, PayloadAction<ApiError>> = (
  state,
  action,
) => ({
  ...state,
  isLoading: false,
  error: action.payload,
});

const setUsersReducer: CaseReducer<
  UserSliceState,
  PayloadAction<UserType[]>
> = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
  };
};

const setSearchFilterReducer: CaseReducer<
  UserSliceState,
  PayloadAction<string | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      searchTerm: action.payload,
    },
  };
};

export const CLEAR_USERS_ACTION = 'users/clear';
export const clearUsers = createAction<void>(CLEAR_USERS_ACTION);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    startLoading: startLoadingReducer,
    hasError: hasErrorReducer,
    setUsers: setUsersReducer,
    setSearchFilter: setSearchFilterReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearUsers, () => initialState);
  },
});

export const { startLoading, hasError, setUsers, setSearchFilter } =
  usersSlice.actions;

export default usersSlice.reducer;

export const fetchUsers = () => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  await grittyApi({
    url: '/a/users',
    method: 'GET',
    withCredentials: true,
    onSuccess: (data) => dispatch(setUsers(data.users)),
    onFailure: (error) => {
      console.error(error.message || error);
      dispatch(hasError(error.message || error));
    },
  });
};

export const createUser =
  ({ email, password }: { email: string; password: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    await grittyApi({
      url: '/a/users',
      method: 'POST',
      data: { email, password },
      withCredentials: true,
      onSuccess: (data) => dispatch(setUsers(data.users)),
      onFailure: (error) => {
        console.error(error.message || error);
        dispatch(hasError(error.message || error));
      },
    });
  };
