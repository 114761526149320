import * as Icons from '@heroicons/react/solid';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';

import ImageDark from 'assets/img/logo-alt.svg';
import ImageLight from 'assets/img/logo.svg';
import { logout } from 'modules/auth/authSlice';

import SidebarSubmenu from './SidebarSubmenu';
import routes from './routes';

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  return <Icon {...props} />;
}

function SidebarContent() {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <a
        className="mx-6 h-16 flex flex-col text-lg font-mont-bold text-gray-800 dark:text-gray-200"
        href="#"
      >
        <img
          aria-hidden="true"
          className="object-contain w-full h-full hidden dark:block"
          src={ImageDark}
          alt="grittytalent"
        />
        <img
          aria-hidden="true"
          className="object-contain w-full h-full block dark:hidden"
          src={ImageLight}
          alt="grittytalent"
        />
      </a>
      <ul className="mt-6">
        {routes.map((route) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.name} />
          ) : (
            <li className="relative px-6 py-3" key={route.name}>
              <NavLink
                exact
                to={route.path}
                className="inline-flex items-center w-full text-sm font-mont-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                activeClassName="text-gray-800 dark:text-gray-100"
              >
                <Route path={route.path} exact={route.exact}>
                  <span
                    className="absolute inset-y-0 left-0 w-1 bg-yellow-400 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                  ></span>
                </Route>
                <Icon
                  className="w-5 h-5"
                  aria-hidden="true"
                  icon={route.icon}
                />
                <span className="ml-4">{route.name}</span>
              </NavLink>
            </li>
          ),
        )}
        <li className="relative px-6 py-3 cursor-pointer" key="logout">
          <span
            onClick={handleLogout}
            className="inline-flex items-center w-full text-sm font-mont-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
          >
            <Icon className="w-5 h-5" aria-hidden="true" icon="LogoutIcon" />
            <span className="ml-4">Logout</span>
          </span>
        </li>
      </ul>
    </div>
  );
}

export default SidebarContent;
