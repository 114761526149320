import cx from 'classnames';
import React from 'react';

import styles from './RippleLoader.module.scss';

export interface RippleLoaderProps {
  color?: 'primary' | 'white';
  fullScreen?: boolean;
  className?: string;
  centered?: boolean;
}

const RippleLoader: React.FC<RippleLoaderProps> = ({
  color = 'primary',
  fullScreen,
  className,
  centered,
}) => {
  const containerClassName = cx(styles.container, {
    [styles.white]: color === 'white',
    [styles.centered]: centered,
  });

  const svg = (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={containerClassName}
    >
      <circle cx="50" cy="50" r="0" fill="none" strokeWidth="4">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
          begin="-0.5s"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
          begin="-0.5s"
        />
      </circle>
      <circle cx="50" cy="50" r="0" fill="none" strokeWidth="4">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1s"
          values="0;40"
          keyTimes="0;1"
          keySplines="0 0.2 0.8 1"
          calcMode="spline"
        />
        <animate
          attributeName="opacity"
          repeatCount="indefinite"
          dur="1s"
          values="1;0"
          keyTimes="0;1"
          keySplines="0.2 0 0.8 1"
          calcMode="spline"
        />
      </circle>
    </svg>
  );

  if (fullScreen) {
    return <div className={cx(styles.fullScreen, className)}>{svg}</div>;
  }

  return <div className={className}>{svg}</div>;
};

export default RippleLoader;
