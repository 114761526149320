import React from 'react';

import ErrorMessage from 'components/ErrorMessage';

const UnsupportedBrowserPage: React.FC = () => {
  return (
    <div className="w-full h-full flex">
      <ErrorMessage
        title="Browser Not Supported"
        message="SoccerStack only supports the most updated versions of Firefox, Edge, Chrome or Safari"
        showEmailMessage
        className="max-w-420 min-h-full my-0 mx-auto"
      />
    </div>
  );
};

export default UnsupportedBrowserPage;
