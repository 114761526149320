import React from 'react';

import MainNavigator from 'modules/main/MainNavigator';

import AppUnauthenticatedRoute from './AppUnauthenticatedRoute';

interface AppRootRouteProps {
  isAuthenticated?: boolean;
  user?: any;
}

const AppRootRoute: React.FC<AppRootRouteProps> = ({
  isAuthenticated,
  user,
}) => {
  if (isAuthenticated) {
    return <MainNavigator user={user} />;
  }

  return <AppUnauthenticatedRoute />;
};

export default AppRootRoute;
