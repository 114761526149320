import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import grittyApi from 'services/gritty';
import { AppDispatch } from 'store';

type ApiError = Record<string, unknown>;
export type DeclinedTalent = {
  rejectedAt: string;
  minorityNotes: string;
  interestNotes: string;
  profileUrl: string;
}

export type TalentType = {
  id: number;
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  isValidated: boolean;
  isRejected: boolean;
  completedOnboarding: boolean;
  workType: number;
  diversity: string;
  minorityNotes: string;
  interestNotes: string;
  profileUrl: string;
  city: string;
  isLoading: boolean;
  error: ApiError | null;
  history: DeclinedTalent[];
};

type TalentSliceState = {
  data: TalentType[];
  filters: {
    workType: number | null;
    status: string | null;
    searchTerm: string | null;
    city: string | null;
  };
  isLoading: boolean;
  error: ApiError | null;
};

const initialState: TalentSliceState = {
  data: [],
  filters: {
    workType: null,
    status: null,
    searchTerm: null,
    city: null,
  },
  isLoading: false,
  error: null,
};

const startLoadingReducer: CaseReducer<TalentSliceState> = (state) => ({
  ...state,
  isLoading: true,
});

const hasErrorReducer: CaseReducer<
  TalentSliceState,
  PayloadAction<ApiError>
> = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.payload,
});

const setTalentReducer: CaseReducer<
  TalentSliceState,
  PayloadAction<TalentType[]>
> = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
  };
};

const setSearchFilterReducer: CaseReducer<
  TalentSliceState,
  PayloadAction<string | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      searchTerm: action.payload,
    },
  };
};

const setWorkTypeFilterReducer: CaseReducer<
  TalentSliceState,
  PayloadAction<number | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      workType: action.payload,
    },
  };
};

const setCityFilterReducer: CaseReducer<
  TalentSliceState,
  PayloadAction<string | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      city: action.payload,
    },
  };
};

const setStatusFilterReducer: CaseReducer<
  TalentSliceState,
  PayloadAction<string | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      status: action.payload,
    },
  };
};

export const CLEAR_TALENT_ACTION = 'talent/clear';
export const clearTalent = createAction<void>(CLEAR_TALENT_ACTION);

const talentSlice = createSlice({
  name: 'talent',
  initialState,
  reducers: {
    startLoading: startLoadingReducer,
    hasError: hasErrorReducer,
    setTalent: setTalentReducer,
    setSearchFilter: setSearchFilterReducer,
    setWorkTypeFilter: setWorkTypeFilterReducer,
    setCityFilter: setCityFilterReducer,
    setStatusFilter: setStatusFilterReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearTalent, () => initialState);
  },
});

export const {
  startLoading,
  hasError,
  setTalent,
  setSearchFilter,
  setWorkTypeFilter,
  setCityFilter,
  setStatusFilter,
} = talentSlice.actions;

export default talentSlice.reducer;

export const fetchTalent = () => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  await grittyApi({
    url: '/a/details/candidates',
    method: 'GET',
    withCredentials: true,
    onSuccess: (data) => dispatch(setTalent(data.talent)),
    onFailure: (error) => {
      console.error(error.message || error);
      dispatch(hasError(error.message || error));
    },
  });
};

export const updateTalentStatus =
  (id: number, isValidated: boolean, isRejected: boolean) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    await grittyApi({
      url: `/a/candidates/${id}/status`,
      method: 'PUT',
      withCredentials: true,
      data: { isValidated, isRejected },
      onSuccess: (data) => dispatch(setTalent(data.talent)),
      onFailure: (error) => {
        console.error(error.message || error);
        dispatch(hasError(error.message || error));
      },
    });
  };
