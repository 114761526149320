import axios, { Method } from 'axios';
import Cookies from 'js-cookie';

type ApiBody = {
  url: string;
  method: Method;
  data?: Record<string, unknown>;
  withCredentials?: boolean;
  onSuccess: (data: any) => void;
  onFailure: (error: any) => void;
  headers?: any;
};

export async function api({
  url,
  method,
  data,
  withCredentials,
  onSuccess,
  onFailure,
  headers,
}: ApiBody) {
  const dataOrParameters = ['GET', 'DELETE'].includes(
    method as unknown as string,
  )
    ? 'params'
    : 'data';

  // default config
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const jwtToken = Cookies.get('jwt');
  if (withCredentials && jwtToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
    axios.defaults.withCredentials = withCredentials;
  }

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParameters]: data,
    })
    .then(({ data: json }) => {
      if (
        json &&
        json.responseResult &&
        json.responseResult.succeeded === false
      ) {
        throw new Error(json.responseResult.message);
      }

      return onSuccess(json);
    })
    .catch((error) => {
      return onFailure(error.response || error);
    });
}

export default api;
