import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import grittyApi from 'services/gritty';
import { AppDispatch } from 'store';

type ApiError = Record<string, unknown>;

export type Member = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
};
type Business = {
  id: number;
  createdAt: string;
  name: string;
  owner: Member;
  members: Member[];
  subscriptionPlan?: string;
  subscriptionExpiryDate: string;
  isLoading: boolean;
  error: ApiError | null;
};

type BusinessesSliceState = {
  data: Business[];
  filters: {
    subscription: string | null;
    searchTerm: string | null;
  };
  isLoading: boolean;
  error: ApiError | null;
};

const initialState: BusinessesSliceState = {
  data: [],
  filters: {
    subscription: null,
    searchTerm: null,
  },
  isLoading: false,
  error: null,
};

const startLoadingReducer: CaseReducer<BusinessesSliceState> = (state) => ({
  ...state,
  isLoading: true,
});

const hasErrorReducer: CaseReducer<
  BusinessesSliceState,
  PayloadAction<ApiError>
> = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.payload,
});

const setBusinessesReducer: CaseReducer<
  BusinessesSliceState,
  PayloadAction<Business[]>
> = (state, action) => {
  return {
    ...state,
    data: action.payload,
    isLoading: false,
  };
};

const setSubscriptionFilterReducer: CaseReducer<
  BusinessesSliceState,
  PayloadAction<string | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      subscription: action.payload,
    },
  };
};

const setSearchFilterReducer: CaseReducer<
  BusinessesSliceState,
  PayloadAction<string | null>
> = (state, action) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      searchTerm: action.payload,
    },
  };
};

export const CLEAR_BUSINESSES_ACTION = 'businesses/clear';
export const clearBusinesses = createAction<void>(CLEAR_BUSINESSES_ACTION);

const businessesSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    startLoading: startLoadingReducer,
    hasError: hasErrorReducer,
    setBusinesses: setBusinessesReducer,
    setSubscriptionFilter: setSubscriptionFilterReducer,
    setSearchFilter: setSearchFilterReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearBusinesses, () => initialState);
  },
});

export const {
  startLoading,
  hasError,
  setBusinesses,
  setSearchFilter,
  setSubscriptionFilter,
} = businessesSlice.actions;

export default businessesSlice.reducer;

export const fetchBusinesses = () => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  await grittyApi({
    url: '/a/details/businesses',
    method: 'GET',
    withCredentials: true,
    onSuccess: (data) => dispatch(setBusinesses(data.businesses)),
    onFailure: (error) => {
      console.error(error.message || error);
      dispatch(hasError(error.message || error));
    },
  });
};

export const deleteBusiness = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  await grittyApi({
    url: `/a/businesses/${id}`,
    method: 'DELETE',
    withCredentials: true,
    onSuccess: (data) => dispatch(setBusinesses(data.businesses)),
    onFailure: (error) => {
      console.error(error.message || error);
      dispatch(hasError(error.message || error));
    },
  });
};

export const changeOwner =
  (id: number, newOwnerId: number, oldOwnerId: number) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    await grittyApi({
      url: `/a/businesses/${id}/owner`,
      method: 'PUT',
      data: { newId: newOwnerId, oldId: oldOwnerId },
      withCredentials: true,
      onSuccess: (data) => dispatch(setBusinesses(data.businesses)),
      onFailure: (error) => {
        console.error(error.message || error);
        dispatch(hasError(error.message || error));
      },
    });
  };

export const changeSubscription =
  (id: number, newExpiryDate: Date) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    await grittyApi({
      url: `/a/businesses/${id}/subscription`,
      method: 'PUT',
      data: { newExpiryDate },
      withCredentials: true,
      onSuccess: (data) => dispatch(setBusinesses(data.businesses)),
      onFailure: (error) => {
        console.error(error.message || error);
        dispatch(hasError(error.message || error));
      },
    });
  };
