import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setOnline } from '../appSlice';

const useConnectionStatusHandler = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateOnlineStatus = async () => {
      if (!navigator.onLine) {
        try {
          await fetch('http://google.com', { mode: 'no-cors' });
        } catch {
          // double check to see if google is online since navigator.onLine is unreliable
          dispatch(setOnline(false));
          return;
        }
      }

      dispatch(setOnline(true));
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [dispatch]);
};

export default useConnectionStatusHandler;
