import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Form, { FieldError } from 'components/Form';
import Input from 'components/Input';
import Label from 'components/Label';
import { useResettableState } from 'hooks/UIHooks';
import { putMe } from 'modules/auth/meSlice';
import { selectMe } from 'modules/auth/selectors';

import styles from './Profile.module.scss';

interface ProfileNameProps {}

export const ProfileName: React.FC<ProfileNameProps> = () => {
  const { isLoading } = useSelector(selectMe);
  const history = useHistory();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameErrorMessage] = useResettableState('', firstName);
  const [lastNameErrorMessage] = useResettableState('', lastName);
  const [errorMessage] = useState<string | null>(null);

  const updateUser = useCallback(async () => {
    await dispatch(
      putMe({ firstName: firstName.trim(), lastName: lastName.trim() }),
    );
    history.push('/app');
  }, [dispatch, putMe, firstName, lastName]);

  return (
    <>
      <Helmet>
        <title> Gritty Talent Admin | Profile </title>
      </Helmet>

      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="h-full flex flex-col overflow-y-auto md:flex-row">
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-full">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-mont-semibold text-gray-700 dark:text-gray-200">
                  What&apos;s your name?
                </h1>

                <Form onSubmit={updateUser}>
                  <Label>
                    <span>First Name *</span>
                    <Input
                      className="mt-1"
                      type="text"
                      placeholder="John"
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.currentTarget.value)}
                    />
                    <FieldError error={firstNameErrorMessage} />
                  </Label>

                  <Label className="mt-4">
                    <span>Last Name *</span>
                    <Input
                      required
                      type="text"
                      placeholder="Jones"
                      className="mt-1"
                      value={lastName}
                      onChange={(e) => setLastName(e.currentTarget.value)}
                    />
                    <FieldError error={lastNameErrorMessage} />
                  </Label>

                  <Button
                    type="submit"
                    disabled={isLoading || !firstName || !lastName}
                    className="mt-4"
                    block
                  >
                    Continue
                  </Button>
                  {errorMessage && (
                    <div className={styles.errorMessage}>{errorMessage}</div>
                  )}
                </Form>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileName;
