import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setCompactScreen } from '../appSlice';
import { getIsCompactScreen } from '../utils';

const useCompactScreenHandler = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    function updateSize() {
      dispatch(setCompactScreen(getIsCompactScreen()));
    }

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [dispatch]);
};

export default useCompactScreenHandler;
