import { createContext } from 'react';

interface SidebarContextInterface {
  toggleSidebar?: any;
  isSidebarOpen: boolean;
  closeSidebar: any;
}

// create context
export const SidebarContext = createContext<SidebarContextInterface>({
  isSidebarOpen: false,
  closeSidebar: null,
});
export default SidebarContext;
