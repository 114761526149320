import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch } from 'react-router-dom';

import ConnectionErrorBoundary from 'modules/common/ConnectionErrorBoundary';

import { Profile } from './components';
import GuardedSignupRoute from './components/GuardedSignupRoute';
import { OnboardingRoutes } from './routes';

export interface OnboardingNavigatorProps {
  user?: any;
  isLoadingUser: boolean;
}

const OnboardingNavigator: React.FC<OnboardingNavigatorProps> = ({
  user,
  isLoadingUser,
}) => {
  const guardedRouteProps = {
    isReady: !isLoadingUser,
    hasApiUser: !!user,
  };

  return (
    <>
      <Helmet>
        <title> SoccerStack | Sign Up </title>
      </Helmet>
      <Switch>
        <ConnectionErrorBoundary>
          <GuardedSignupRoute
            path={OnboardingRoutes.PROFILE}
            component={Profile}
            {...guardedRouteProps}
          />
        </ConnectionErrorBoundary>
      </Switch>
    </>
  );
};

export default OnboardingNavigator;
