import React, { useCallback } from 'react';

interface FormProps {
  onSubmit: () => void;
  className?: string;
  children: React.ReactNode;
}

const Form: React.FC<FormProps> = ({ onSubmit, children }) => {
  const handleOnSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmit();
    },
    [onSubmit],
  );

  return <form onSubmit={handleOnSubmit}>{children}</form>;
};

export default Form;
