import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AccessibleNavigationAnnouncer from 'components/AccessibleNavigationAnnouncer';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorMessage from 'components/ErrorMessage';

import AppNavigator from './AppNavigator';
import { persistor, store, history } from './store';

const helmetContext = {};

const AppContent = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <HelmetProvider context={helmetContext}>
          <ConnectedRouter history={history}>
            <AccessibleNavigationAnnouncer />
            <AppNavigator />
          </ConnectedRouter>
        </HelmetProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

const App = () => (
  <ErrorBoundary
    fallback={({ error }) => (
      <ErrorMessage
        title="An error ocurred"
        message={
          process.env.NODE_ENV === 'development'
            ? error.message
            : 'Sorry, something went wrong. Refresh your page to try again.'
        }
        showEmailMessage
      />
    )}
  >
    <AppContent />
  </ErrorBoundary>
);

export default App;
