import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import _ from 'lodash';

import grittyApi from 'services/gritty';
import { AppDispatch } from 'store';

import { OnboardingRoutes } from './Onboarding/routes';
import { clearMe, setProfile } from './meSlice';

type ApiError = Record<string, unknown>;

type AuthSliceState = {
  redirectRoute: string | null;
  isLoading: boolean;
  error: ApiError | null;
};

const initialState: AuthSliceState = {
  redirectRoute: null,
  isLoading: false,
  error: null,
};

const setRedirectRouteReducer: CaseReducer<
  AuthSliceState,
  PayloadAction<string>
> = (state, action) => ({
  ...state,
  redirectRoute: action.payload,
});

const startLoadingReducer: CaseReducer<AuthSliceState> = (state) => ({
  ...state,
  isLoading: true,
});

const stopLoadingReducer: CaseReducer<AuthSliceState> = (state) => ({
  ...state,
  isLoading: false,
});

const hasErrorReducer: CaseReducer<AuthSliceState, PayloadAction<ApiError>> = (
  state,
  action,
) => ({
  ...state,
  isLoading: false,
  error: action.payload,
});

export const CLEAR_AUTH_ACTION = 'auth/clearAuth';
export const clearAuth = createAction<void>(CLEAR_AUTH_ACTION);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading: startLoadingReducer,
    stopLoading: stopLoadingReducer,
    hasError: hasErrorReducer,
    setRedirectRoute: setRedirectRouteReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuth, () => initialState);
  },
});

export const { setRedirectRoute, startLoading, stopLoading, hasError } =
  authSlice.actions;

export default authSlice.reducer;

const handleLoginResponse = (
  data: any,
  dispatch: AppDispatch,
  redirect: string | null,
) => {
  if (data.responseResult?.succeeded === true) {
    const { continuationToken } = data;
    dispatch(stopLoading());

    if (!data.user) {
      dispatch(logout());
      return;
    }

    dispatch(setProfile(data.user));

    if (data.mustChangePwd === true) {
      dispatch(push('/resetPassword', { continuationToken }));
    } else {
      Cookies.set('jwt', data.jwt, { expires: 365 });
      _.delay(() => {
        if (!data.user.firstName) {
          dispatch(push(OnboardingRoutes.PROFILE));
        } else {
          dispatch(push(redirect || '/app'));
        }
      }, 2000);
    }
  } else {
    throw new Error(data.responseResult);
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  await grittyApi({
    url: '/auth/logout',
    method: 'GET',
    withCredentials: true,
    onSuccess: () => {
      Cookies.remove('jwt');
      dispatch(clearAuth());
      dispatch(clearMe());
      document.location = '/login';
      // dispatch(push('/login'));
    },
    onFailure: (error) => {
      console.error(error.message);
      Cookies.remove('jwt');
      dispatch(clearAuth());
      dispatch(clearMe());
      document.location = '/login';
      // dispatch(push('/login'));
    },
  });
};

export const login =
  (
    { email, password }: { email: string; password: string },
    redirect: string | null,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    await grittyApi({
      url: '/auth/login/local',
      method: 'POST',
      data: { email, password },
      onSuccess: (data) => handleLoginResponse(data, dispatch, redirect),
      onFailure: (error) => {
        console.error(error.message);
        dispatch(hasError(error.message));
      },
    });
  };
