import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from './Login';
import OnboardingNavigator, {
  OnboardingNavigatorProps,
} from './Onboarding/OnboardingNavigator';
import { ONBOARDING_PATHS } from './Onboarding/routes';
import { AuthRoutes } from './routes';

const AuthNavigator: React.FC<OnboardingNavigatorProps> = (props) => (
  <Switch>
    <Route path={AuthRoutes.LOGIN} component={Login} />
    <Route path={ONBOARDING_PATHS}>
      <OnboardingNavigator {...props} />
    </Route>
  </Switch>
);

export default AuthNavigator;
