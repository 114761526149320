import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';

export function useResettableState<V>(
  initialValue: V,
  triggerValue: any,
): [V, Dispatch<SetStateAction<V>>] {
  const trigger = useRef();
  const [value, setValue] = useState(initialValue);

  const finalValue = trigger.current !== triggerValue ? initialValue : value;

  useEffect(() => {
    if (trigger.current !== triggerValue) {
      trigger.current = triggerValue;
      setValue(initialValue);
    }
  }, [initialValue, triggerValue]);

  return [finalValue, setValue];
}
