import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthRoutes } from 'modules/auth/routes';

interface GetRedirectRouteParams extends Pick<RouteProps, 'path'> {
  hasApiUser: boolean;
}

const getRedirectRoute = ({
  path,
  hasApiUser,
}: {
  path: any;
  hasApiUser: boolean;
}) => {
  switch (path) {
    default:
      if (!hasApiUser) {
        return AuthRoutes.LOGIN;
      }
  }

  return null;
};

interface GuardedSignupRouteProps extends RouteProps, GetRedirectRouteParams {
  isReady: boolean;
}

const GuardedSignupRoute = ({
  isReady,
  hasApiUser,
  path,
  children,
  component,
  render,
  ...rest
}: GuardedSignupRouteProps) => (
  <Route
    {...rest}
    path={path}
    render={(childProps) => {
      if (!isReady) {
        return null;
      }

      const redirectTo = getRedirectRoute({
        path,
        hasApiUser,
      });

      if (redirectTo) {
        return <Redirect to={redirectTo} />;
      }

      if (component) {
        return React.createElement(component, childProps);
      }

      if (render) {
        return render(childProps);
      }

      return children;
    }}
  />
);
export default GuardedSignupRoute;
