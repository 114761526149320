import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ImageLight from 'assets/img/logo.svg';
import { RippleLoader } from 'components/Loader';

import EmailInputContainer from '../components/EmailInputContainer';
import { selectMe } from '../selectors';

const Login = ({ from }: { from?: Location }) => {
  const { profile } = useSelector(selectMe);
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!!profile && !_.isEmpty(profile)) {
      dispatch(push(from?.pathname || '/app'));
    }
  }, [from, dispatch]);

  return (
    <>
      <Helmet>
        <title>Gritty Talent Admin | Login</title>
      </Helmet>

      <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
        <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <div className="flex flex-col overflow-y-auto md:flex-row">
            <div className="h-32 md:h-auto md:w-1/2">
              <div className="object-cover w-full h-full bg-gray-100 flex justify-center items-center">
                <div className="h-32 w-32 relative">
                  <img
                    aria-hidden="true"
                    className="object-contain w-full h-full block"
                    src={ImageLight}
                    alt="grittytalent"
                  />
                </div>
              </div>
            </div>
            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
              <div className="w-full">
                <h1 className="mb-4 text-xl font-mont-semibold text-gray-700 dark:text-gray-200">
                  Login
                </h1>

                {!profile || _.isEmpty(profile) ? (
                  <>
                    <EmailInputContainer
                      email={email}
                      password={password}
                      setEmail={setEmail}
                      setPassword={setPassword}
                    />
                    <p className="mt-4">
                      <Link
                        className="text-sm font-mont-semibold text-purple-600 dark:text-purple-400 hover:underline"
                        to="/forgot-password"
                      >
                        Forgot your password?
                      </Link>
                    </p>
                  </>
                ) : (
                  <RippleLoader />
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
