import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import ErrorMessage from 'components/ErrorMessage';
import { selectIsOnline } from 'modules/app/selectors';
import { RootState } from 'store';

const mapState = (state: RootState) => ({
  isOnline: selectIsOnline(state),
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  children: React.ReactNode;
  connectionErrorTitle?: string;
  connectionErrorMessage?: string;
  commonErrorTitle?: string;
  commonErrorMessage?: string;
  showSadPersonOnError?: boolean;
  className?: string;
};

type State = {
  hasError: boolean;
  errorTitle: string | null;
  errorMessage: string | null;
};

class ConnectionErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      errorTitle: null,
      errorMessage: null,
    };
  }

  componentDidCatch() {
    const {
      connectionErrorTitle = 'No Internet connection',
      connectionErrorMessage = 'Please verify your internet connection',
      commonErrorTitle = 'Error loading data',
      commonErrorMessage = 'Please refresh the page to try again',
      isOnline,
    } = this.props;

    this.setState({
      hasError: true,
      errorTitle: isOnline ? commonErrorTitle : connectionErrorTitle,
      errorMessage: isOnline ? commonErrorMessage : connectionErrorMessage,
    });
  }

  componentDidUpdate(prevProps: Props) {
    const wasOffline = !prevProps.isOnline;

    const { isOnline } = this.props;
    const { hasError } = this.state;

    // if has error due to internet connection and connection gets back
    // try to fetch again
    if (wasOffline && isOnline && hasError) {
      this.setState({
        hasError: false,
        errorTitle: null,
        errorMessage: null,
      });
    }
  }

  render() {
    const { children, className } = this.props;
    const { hasError, errorTitle, errorMessage } = this.state;

    if (hasError) {
      return (
        <ErrorMessage
          title={errorTitle || 'Error'}
          message={errorMessage || 'Something went wrong'}
          className={className}
        />
      );
    }

    return children;
  }
}

export default connector(ConnectionErrorBoundary);
