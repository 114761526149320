/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
  {
    path: '/app/dashboard', // the url
    icon: 'HomeIcon',
    name: 'Dashboard', // name that appear in Sidebar
  },
  {
    path: '/app/businesses', // the url
    icon: 'BriefcaseIcon',
    name: 'Businesses', // name that appear in Sidebar
  },
  {
    path: '/app/talent', // the url
    icon: 'StarIcon',
    name: 'Talent', // name that appear in Sidebar
  },
  {
    path: '/app/users',
    icon: 'UsersIcon',
    name: 'Users',
  },
];

export default routes;
