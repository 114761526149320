import MenuIcon from '@heroicons/react/solid/MenuIcon';
import MoonIcon from '@heroicons/react/solid/MoonIcon';
import SunIcon from '@heroicons/react/solid/SunIcon';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';

import SidebarContext from '../../context/SidebarContext';
import { ThemeContext } from '../../context/ThemeContext';

function Header() {
  const { mode, toggleMode } = useContext(ThemeContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const { location } = useSelector((state: RootState) => state.router);
  let title = location.pathname.split('/').pop();
  switch (title) {
    case 'dashboard':
      title = 'Overview';
      break;
    case 'businesses':
      title = 'Manage Customers';
      break;
    case 'talent':
      title = 'Manage Talent';
      break;
  }
  return (
    <header className="flex z-40 py-4 bg-white bg-gray-50 shadow-bottom dark:bg-gray-900">
      <div className="container flex items-center justify-between h-full px-6 mx-auto text-gray-500 dark:text-yellow-400">
        <span className="hidden lg:flex">&nbsp;</span>
        {/* <!-- Mobile hamburger --> */}
        <button
          className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-purple"
          onClick={toggleSidebar}
          aria-label="Menu"
        >
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>
        <div className="text-lg font-mont-semibold leading-7 text-gray-500 dark:text-gray-300 sm:text-xl capitalize">
          {title}
        </div>
        <ul className="flex items-center flex-shrink-0 space-x-6">
          {/* <!-- Theme toggler --> */}
          <li className="flex">
            <button
              className="rounded-md focus:outline-none focus:shadow-outline-purple"
              onClick={toggleMode}
              aria-label="Toggle color mode"
            >
              {mode === 'dark' ? (
                <SunIcon className="w-5 h-5" aria-hidden="true" />
              ) : (
                <MoonIcon className="w-5 h-5" aria-hidden="true" />
              )}
            </button>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
