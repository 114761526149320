import cx from 'classnames';
import React from 'react';

export interface ErrorMessageProps {
  title: string;
  message: string;
  showEmailMessage?: boolean;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  title,
  message,
  showEmailMessage,
  className,
}) => (
  <div
    className={cx(
      'flex-1 flex flex-col items-center justify-center relative text-center',
      className,
    )}
  >
    <div className={'my-5 mx-0 text-lg font-mont-bold'}>{title}</div>
    <div className={'text-lg text-gray-800'}>{message}</div>

    {showEmailMessage && (
      <div className={'my-30 mx-0 text-lg text-gray-800'}>
        If you’re still experience issues, send an email to:{' '}
        <a
          className={'text-blue-500 hover:opacity-90 active:opacity-90'}
          href="mailto:dev@grittytalent.tv"
        >
          dev@grittytalent.tv
        </a>
      </div>
    )}
  </div>
);

export default ErrorMessage;
