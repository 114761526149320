import React, { Component, ErrorInfo, ReactNode, ReactElement } from 'react';

interface Props {
  children: ReactNode;
  /**
   * A fallback component that gets rendered when the error boundary encounters an error.
   *
   * Can either provide a React Component, or a function that returns React Component as
   * a valid fallback prop. If a function is provided, the function will be called with
   * the error, the component stack, and an function that resets the error boundary on error.
   *
   */
  fallback?: React.ReactElement | FallbackRender;
}

type ErrorBoundaryState = {
  componentStack: string | null;
  error: Error | null;
};

const INITIAL_STATE = {
  componentStack: null,
  error: null,
};

export const UNKNOWN_COMPONENT = 'unknown';

export type FallbackRender = (errorData: {
  error: Error;
  componentStack: string | null;
  resetError(): void;
}) => ReactElement;

class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  public state: ErrorBoundaryState = INITIAL_STATE;

  public componentDidCatch(error: Error, { componentStack }: ErrorInfo): void {
    console.error('Uncaught error:', error, componentStack);
    this.setState({ error, componentStack });
  }

  public resetErrorBoundary: () => void = () => {
    this.setState(INITIAL_STATE);
  };

  public render() {
    const { fallback, children } = this.props;
    const { error, componentStack } = this.state;

    if (error) {
      let element: React.ReactElement | undefined = undefined;
      if (typeof fallback === 'function') {
        element = fallback({
          error,
          componentStack,
          resetError: this.resetErrorBoundary,
        });
      } else {
        element = fallback;
      }

      if (React.isValidElement(element)) {
        return element;
      }

      if (fallback) {
        console.warn('fallback did not produce a valid ReactElement');
      }

      // Fail gracefully if no fallback provided or is not valid
      return null;
    }

    if (typeof children === 'function') {
      return children();
    }
    return children;
  }
}

export default ErrorBoundary;
