import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import appReducer from 'modules/app/appSlice';
import authReducer from 'modules/auth/authSlice';
import meReducer from 'modules/auth/meSlice';
import businessesReducer from 'modules/businesses/businessesSlice';
import statsReducer from 'modules/dashboard/statsSlice';
import talentReducer from 'modules/talent/talentSlice';
import usersReducer from 'modules/users/usersSlice';

export default (history: History<unknown>) =>
  combineReducers({
    app: appReducer,
    auth: authReducer,
    me: meReducer,
    stats: statsReducer,
    businesses: businessesReducer,
    talent: talentReducer,
    users: usersReducer,
    router: connectRouter(history),
  });
