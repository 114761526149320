import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RippleLoader } from 'components/Loader';
import AppRootRoute from 'modules/app/AppRootRoute';
import UnsupportedBrowserPage from 'modules/app/UnsupportedBrowserPage';
import {
  useCompactScreenHandler,
  useConnectionStatusHandler,
} from 'modules/app/hooks';
import { getIsValidBrowser } from 'modules/app/utils';
import AuthNavigator from 'modules/auth/AuthNavigator';
import { fetchMe } from 'modules/auth/meSlice';
import { AUTH_PATHS } from 'modules/auth/routes';
import { selectMe } from 'modules/auth/selectors';

interface AppNavigatorProps {}

const AppNavigator: React.FC<AppNavigatorProps> = () => {
  const dispatch = useDispatch();
  const { profile, isLoading, error } = useSelector(selectMe);
  useConnectionStatusHandler();
  useCompactScreenHandler();
  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  if (isLoading) {
    return <RippleLoader fullScreen />;
  }

  if (error) {
    throw error;
  }

  const isAuthenticated = !!profile && !_.isEmpty(profile);

  // const isMobile = getIsMobile();
  const isValidBrowser = getIsValidBrowser();

  if (isValidBrowser) {
    return (
      <Switch>
        <Route path={AUTH_PATHS}>
          <AuthNavigator user={profile} isLoadingUser={isLoading} />
        </Route>
        <Route path="/app">
          <AppRootRoute isAuthenticated={isAuthenticated} />
        </Route>
        <Redirect from="/" to="/app" />
      </Switch>
    );
  }

  return <UnsupportedBrowserPage />;
};

export default AppNavigator;
