import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';

import SidebarProvider from 'context/SidebarProvider';

import App from './App';
import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import 'styles/base.scss';

dotenv.config();

const Main = () => (
  <SidebarProvider>
    <AppWrapper usePreferences={true}>
      <App />
    </AppWrapper>
  </SidebarProvider>
);
ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
