import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

// import { useQueryString } from 'hooks/UtilHooks';

// interface QueryStringValues {}

const AppUnauthenticatedRoute = () => {
  const location = useLocation();

  // const queryString = useQueryString<QueryStringValues>();

  return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
};

export default AppUnauthenticatedRoute;
