import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getIsCompactScreen } from './utils';

type AppSliceState = {
  isOnline: boolean;
  isCompactScreen: boolean;
};

const initialState: AppSliceState = {
  isOnline: true,
  isCompactScreen: getIsCompactScreen(),
};

const setOnlineReducer: CaseReducer<AppSliceState, PayloadAction<boolean>> = (
  state,
  action,
) => ({
  ...state,
  isOnline: action.payload,
});

const setCompactScreenReducer: CaseReducer<
  AppSliceState,
  PayloadAction<boolean>
> = (state, action) => ({
  ...state,
  isCompactScreen: action.payload,
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setOnline: setOnlineReducer,
    setCompactScreen: setCompactScreenReducer,
  },
});

export const { setOnline, setCompactScreen } = appSlice.actions;

export default appSlice.reducer;
