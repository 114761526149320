import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import grittyApi from 'services/gritty';
import { AppDispatch } from 'store';

type ApiError = Record<string, unknown>;

type ByMonth = Record<string, number>;
type ByQuarter = Record<string, number>;
type ByYear = Record<string, number>;
type ByWorkType = Record<string, number>;
type ByPackage = Record<string, number>;
type ByGenre = Record<string, number>;

type TalentStats = {
  total: number;
  byMonth: ByMonth;
  byQuarter: ByQuarter;
  byYear: ByYear;
  byWorkType: ByWorkType;
  byGenre: ByGenre;
  isLoading: boolean;
  error: ApiError | null;
};

type BusinessStats = {
  total: number;
  byMonth: ByMonth;
  byQuarter: ByQuarter;
  byYear: ByYear;
  byPackage: ByPackage;
  isLoading: boolean;
  error: ApiError | null;
};

type StatsSliceState = {
  talent: TalentStats;
  business: BusinessStats;
};

const initialState: StatsSliceState = {
  talent: {
    total: 0,
    byMonth: {},
    byQuarter: {},
    byYear: {},
    byWorkType: {},
    byGenre: {},
    isLoading: false,
    error: null,
  },
  business: {
    total: 0,
    byMonth: {},
    byQuarter: {},
    byYear: {},
    byPackage: {},
    isLoading: false,
    error: null,
  },
};

const startLoadingTalentReducer: CaseReducer<StatsSliceState> = (state) => ({
  ...state,
  talent: {
    ...state.talent,
    isLoading: true,
  },
});

const startLoadingBusinessReducer: CaseReducer<StatsSliceState> = (state) => ({
  ...state,
  business: {
    ...state.business,
    isLoading: true,
  },
});

const hasTalentErrorReducer: CaseReducer<
  StatsSliceState,
  PayloadAction<ApiError>
> = (state, action) => ({
  ...state,
  talent: {
    ...state.talent,
    isLoading: false,
    error: action.payload,
  },
});

const hasBusinessErrorReducer: CaseReducer<
  StatsSliceState,
  PayloadAction<ApiError>
> = (state, action) => ({
  ...state,
  business: {
    ...state.business,
    isLoading: false,
    error: action.payload,
  },
});

const setTalentStatsReducer: CaseReducer<
  StatsSliceState,
  PayloadAction<TalentStats>
> = (state, action) => {
  return {
    ...state,
    talent: {
      ...state.talent,
      isLoading: false,
      total: action.payload.total,
      byMonth: action.payload.byMonth,
      byQuarter: action.payload.byQuarter,
      byYear: action.payload.byYear,
      byWorkType: action.payload.byWorkType,
      byGenre: action.payload.byGenre,
    },
  };
};

const setBusinessStatsReducer: CaseReducer<
  StatsSliceState,
  PayloadAction<BusinessStats>
> = (state, action) => ({
  ...state,
  business: {
    ...state.business,
    isLoading: false,
    total: action.payload.total,
    byMonth: action.payload.byMonth,
    byQuarter: action.payload.byQuarter,
    byYear: action.payload.byYear,
    byPackage: action.payload.byPackage,
  },
});

export const CLEAR_STATS_ACTION = 'stats/clearStats';
export const clearStats = createAction<void>(CLEAR_STATS_ACTION);

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    startLoadingTalent: startLoadingTalentReducer,
    startLoadingBusiness: startLoadingBusinessReducer,
    hasTalentError: hasTalentErrorReducer,
    hasBusinessError: hasBusinessErrorReducer,
    setTalentStats: setTalentStatsReducer,
    setBusinessStats: setBusinessStatsReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearStats, () => initialState);
  },
});

export const {
  startLoadingTalent,
  startLoadingBusiness,
  hasTalentError,
  hasBusinessError,
  setTalentStats,
  setBusinessStats,
} = statsSlice.actions;

export default statsSlice.reducer;

export const fetchTalentStats = () => async (dispatch: AppDispatch) => {
  dispatch(startLoadingTalent());
  await grittyApi({
    url: '/a/stats/candidates',
    method: 'GET',
    withCredentials: true,
    onSuccess: (data) => dispatch(setTalentStats(data.stats)),
    onFailure: (error) => {
      console.error(error.message || error);
      dispatch(hasTalentError(error.message || error));
    },
  });
};

export const fetchBusinessStats = () => async (dispatch: AppDispatch) => {
  dispatch(startLoadingBusiness());
  await grittyApi({
    url: '/a/stats/businesses',
    method: 'GET',
    withCredentials: true,
    onSuccess: (data) => dispatch(setBusinessStats(data.stats)),
    onFailure: (error) => {
      console.error(error.message || error);
      dispatch(hasBusinessError(error.message || error));
    },
  });
};
