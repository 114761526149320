import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import Form, { FieldError } from 'components/Form';
import Input from 'components/Input';
import Label from 'components/Label';

import { login } from '../authSlice';
import { selectAuth } from '../selectors';

import styles from './EmailInputContainer.module.scss';

interface EmailInputScreenProps {
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
}

const EmailInputContainer: React.FC<EmailInputScreenProps> = ({
  email,
  password,
  setEmail,
  setPassword,
}) => {
  const dispatch = useDispatch();
  const { isLoading, redirectRoute } = useSelector(selectAuth);
  const [errorMessage] = useState('');
  const [emailErrorMessage] = useState('');
  const [passwordErrorMessage] = useState('');

  const attemptLogin = async () => {
    // TODO: validation
    dispatch(login({ email, password }, redirectRoute));
  };

  return (
    <>
      <Form onSubmit={attemptLogin}>
        <Label>
          <span>Email *</span>
          <Input
            className="p-3 mt-1"
            type="email"
            placeholder="john@doe.com"
            required
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <FieldError error={emailErrorMessage} />
        </Label>

        <Label className="mt-4">
          <span>Password *</span>
          <Input
            autoComplete="current-password"
            required
            type="password"
            placeholder="***************"
            className="p-3 mt-1"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <FieldError error={passwordErrorMessage} />
        </Label>

        <Button
          type="submit"
          disabled={isLoading || !email || !password}
          className="mt-4"
          block
        >
          Continue
        </Button>
        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </Form>
    </>
  );
};

export default EmailInputContainer;
