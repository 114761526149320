import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import ThemedSuspense from 'components/ThemedSuspense';
import SidebarContext from 'context/SidebarContext';

import Main from './Main';

const Dashboard = lazy(() => import('../dashboard'));
const Businesses = lazy(() => import('../businesses'));
const Talent = lazy(() => import('../talent'));
const Users = lazy(() => import('../users'));
const Page404 = lazy(() => import('./404'));
const Blank = lazy(() => import('./Blank'));

interface MainNavigatorProps {
  user: any;
}

const routes = [
  {
    path: '/businesses', // the url
    component: Businesses, // view rendered
  },
  {
    path: '/talent', // the url
    component: Talent, // view rendered
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/dashboard', // the url
    component: Dashboard, // view rendered
  },
  {
    path: '/404',
    component: Page404,
  },
  {
    path: '/blank',
    component: Blank,
  },
];

const MainNavigator: React.FC<MainNavigatorProps> = () => {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext);
  const location = useLocation();

  useEffect(() => {
    closeSidebar();
  }, [location]);

  return (
    <>
      <Helmet>
        <title> Gritty Talent Admin </title>
      </Helmet>

      <div
        className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
          isSidebarOpen && 'overflow-hidden'
        }`}
      >
        <Sidebar />

        <div className="flex flex-col flex-1 w-full">
          <Header />
          <Main>
            <Suspense fallback={<ThemedSuspense />}>
              <Switch>
                {routes.map((route, i) => {
                  return route.component ? (
                    <Route
                      key={i}
                      exact={true}
                      path={`/app${route.path}`}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect exact from="/app" to="/app/dashboard" />
                <Route component={Page404} />
              </Switch>
            </Suspense>
          </Main>
        </div>
      </div>
    </>
  );
};

export default MainNavigator;
