import React, { useLayoutEffect, useMemo } from 'react';

import { ThemeContext } from './context/ThemeContext';
import defaultTheme from './themes/default';
import useDarkMode from './utils/useDarkMode';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  dark?: boolean;
  usePreferences?: boolean;
}

const AppWrapper: React.FC<Props> = ({
  children,
  dark,
  usePreferences = false,
}) => {
  const [mode, setMode, toggleMode] = useDarkMode(usePreferences);

  useLayoutEffect(() => {
    if (dark) {
      if (setMode != null) {
        setMode('dark');
      }
      document.documentElement.classList.add(`dark`);
    }
  }, [dark, setMode]);

  const value = useMemo(
    () => ({
      theme: defaultTheme,
      mode,
      toggleMode,
    }),
    [mode, toggleMode],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default AppWrapper;
