import cx from 'classnames';
import React, { useState, useEffect, useRef, useMemo } from 'react';

import styles from './FieldError.module.scss';

export interface FieldErrorProps {
  error?: string;
  className?: string;
}

const FieldError: React.FC<FieldErrorProps> = ({ error, className }) => {
  const [isVisible, setVisible] = useState(false);

  const [text, setText] = useState(error);
  const [textHeight, setTextHeight] = useState(0);
  const textRef = useRef<HTMLDivElement>(null);

  const localErrorTimeoutRef = useRef<number | NodeJS.Timeout>();

  useEffect(() => {
    if (error) {
      // show error logic
      // we first set the text and after rendered we add the styles so it can properly animate
      setText(error);

      requestAnimationFrame(() => {
        setVisible(true);
      });
    } else {
      // hide error logic
      // wait the animation to finish before resetting the text
      setVisible(false);

      clearTimeout(localErrorTimeoutRef.current as number);
      localErrorTimeoutRef.current = setTimeout(() => {
        setText(error);
      }, 200);
    }

    return () => {
      clearTimeout(localErrorTimeoutRef.current as number);
    };
  }, [error]);

  useEffect(() => {
    return setTextHeight(textRef.current?.clientHeight || 0);
  }, [text]);

  const containerStyles = useMemo(
    () => ({
      opacity: isVisible ? 1 : 0,
      transform: `translateY(-${isVisible ? 0 : textHeight}px)`,
      maxHeight: isVisible ? textHeight : 0,
      marginTop: isVisible ? 5 : 0,
    }),
    [isVisible, textHeight],
  );

  if (!text) {
    return null;
  }

  return (
    <div className={cx(styles.container, className)} style={containerStyles}>
      <div ref={textRef} className={styles.text}>
        {text}
      </div>
    </div>
  );
};

export default FieldError;
