import {
  CaseReducer,
  PayloadAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';
import { snakeToCamelCase } from 'json-style-converter/es5';

import grittyApi from 'services/gritty';
import { AppDispatch } from 'store';

type User = Record<string, unknown>;
type ApiError = Record<string, unknown>;

type MeSliceState = {
  profile: User | null;
  isLoading: boolean;
  error: ApiError | null;
};

const initialState: MeSliceState = {
  profile: null,
  isLoading: true,
  error: null,
};

const setProfileReducer: CaseReducer<MeSliceState, PayloadAction<User>> = (
  state,
  action,
) => ({
  ...state,
  profile: action.payload,
  isLoading: false,
});

const startLoadingReducer: CaseReducer<MeSliceState> = (state) => ({
  ...state,
  isLoading: true,
});

const hasErrorReducer: CaseReducer<MeSliceState, PayloadAction<ApiError>> = (
  state,
  action,
) => ({
  ...state,
  isLoading: false,
  error: action.payload,
});

export const CLEAR_ME_ACTION = 'me/clearMe';
export const clearMe = createAction<void>(CLEAR_ME_ACTION);

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    startLoading: startLoadingReducer,
    hasError: hasErrorReducer,
    setProfile: setProfileReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(clearMe, () => initialState);
  },
});

export const { setProfile, startLoading, hasError } = meSlice.actions;

export default meSlice.reducer;

export const fetchMe = () => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  await grittyApi({
    url: '/user',
    method: 'GET',
    withCredentials: true,
    onSuccess: (data) => {
      if (data && data.user) {
        dispatch(setProfile(snakeToCamelCase(data.user)));
      }
      return data;
    },
    onFailure: (error) => {
      console.error(error.message);
      dispatch(hasError(error.message));
    },
  });
};

export const putMe =
  (data: { firstName: string; lastName: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    await grittyApi({
      url: '/user',
      method: 'PUT',
      data,
      withCredentials: true,
      onSuccess: (data) => {
        if (data && data.user) {
          dispatch(setProfile(snakeToCamelCase(data.user)));
        }
        return data;
      },
      onFailure: (error) => {
        console.error(error.message);
        dispatch(hasError(error.message));
      },
    });
  };
