import { detect } from 'detect-browser';
import { isMobileOnly } from 'react-device-detect';

export const SMALL_SCREEN_BREAKPOINT = 850;

export const getIsCompactScreen = () =>
  window.innerWidth <= SMALL_SCREEN_BREAKPOINT;

export const getIsMobile = () => isMobileOnly;

export const getIsValidBrowser = () => {
  // Prevents any false negatives. Allows for false positives.
  try {
    const browser = detect();
    if (!browser) throw new Error('Browser undefined');
    const browserVersion = parseInt(browser.version || '');
    if (browser.name === 'chrome' && browserVersion < 83) {
      return false;
    } else if (browser.name === 'firefox' && browserVersion < 79) {
      return false;
    } else if (browser.name.includes('edge') && browserVersion < 88) {
      return false;
    } else if (browser.name === 'safari' && browserVersion < 13) {
      return false;
    }
  } catch (e) {
    console.log('detect-browser: Could not detect browser & version', e);
  }
  return true;
};
