import React, { useState } from 'react';

import { ProfileName } from './';

enum ProfileScreens {
  NAME,
}

const Profile = () => {
  const [currentScreen] = useState<ProfileScreens>(ProfileScreens.NAME);

  switch (currentScreen) {
    case ProfileScreens.NAME:
      return <ProfileName />;
  }
};

export default Profile;
